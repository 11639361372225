import React, { Component } from 'react';

class FooterSection extends Component {
    state = {
        "data": {
          "image": "/img/logo.png",
          "linkText_1": "Useful Links",
          "linkText_2": "Contact Us",
          // "linkText_3": "Download",
          "iconList": [
            // {
            //   "id": 1,
            //   "link": "facebook",
            //   "iconClass": "fab fa-facebook-f",
            //   "href": ""
            // },
            // {
            //   "id": 2,
            //   "link": "linkedin",
            //   "iconClass": "fab fa-linkedin",
            //   "href": "https://www.linkedin.com/company/bidlocal-in"
            // },
            // {
            //   "id": 3,
            //   "link": "instagram",
            //   "iconClass": "fab fa-instagram",
            //   "href": "https://instagram.com/bidlocal.in?igshid=y81b895ys1le"
            // },
            // {
            //   "id": 4,
            //   "link": "twitter",
            //   "iconClass": "fab fa-twitter",
            //   "href": "https://twitter.com/Bidlocal_in?s=09"
            // },
          ],
        },
        "iconList": [
          // {
          //   "id": 1,
          //   "link": "facebook",
          //   "iconClass": "fab fa-facebook-f",
          //   "href": "https://www.facebook.com/Bidlocalin-107569917688486/"
          // },
          // {
          //   "id": 2,
          //   "link": "linkedin",
          //   "iconClass": "fab fa-linkedin",
          //   "href": "https://www.linkedin.com/company/bidlocal-in"
          // },
          // {
          //   "id": 3,
          //   "link": "instagram",
          //   "iconClass": "fab fa-instagram",
          //   "href": "https://instagram.com/bidlocal.in?igshid=y81b895ys1le"
          // },
          // {
          //   "id": 4,
          //   "link": "twitter",
          //   "iconClass": "fab fa-twitter",
          //   "href": "https://twitter.com/Bidlocal_in?s=09"
          // },
        ],
        "footerList_1": [
          {
            "id": 1,
            "text": "Home",
            "href": "#home",
            "className": 'nav-link p-0 scroll'
          },
          {
            "id": 2,
            "text": "About Us",
            "href": "#features",
            "className": 'nav-link p-0 scroll'
          },
          {
            "id": 3,
            "text": "Join Us",
            "href": "#joinus",
            "className": 'nav-link p-0 scroll'
          },
          {
            "id": 4,
            "text": "Privacy Policy",
            "href": "/privacy-policy",
            "className": 'nav-link p-0'
          },
        ],
        
        "footerList_3": [
          // {
          //   "id": 1,
          //   "image": "/img/google-play-black.png"
          // },
          // {
          //   "id": 2,
          //   "image": "/img/app-store-black.png"
          // }
        ],
        "iconList2": [
            {
                "id": 1,
                "iconClass": "fas fa-phone-alt",
                "text": "+91-7009444797"
            },
            {
                "id": 2,
                "iconClass": "fas fa-envelope",
                "text": "sanghisoftech@gmail.com"
            }
        ]
      }
    
    render() {
        return (
            <div>
                <div className="height-emulator d-none d-lg-block" />
                <footer className="footer-area footer-fixed">
                    {/* Footer Top */}
                    <div className="footer-top ptb_100">
                        <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Logo */}
                                <a className="navbar-brand" href="/#">
                                <img className="logo" src={this.state.data.image} alt="" />
                                </a>
                                {/* Social Icons */}
                                <div className="social-icons d-flex">
                                    {this.state.iconList.map((item, idx) => {
                                        return(
                                            <a key={`fi_${idx}`} target={"_blank"} className={item.link} href={item.href}>
                                                <i className={item.iconClass} />
                                                <i className={item.iconClass} />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.data.linkText_1}</h3>
                                <ul>
                                    {this.state.footerList_1.map((item, idx) => {
                                        return(
                                            <li key={`flo_${idx}`} className="py-2"><a className={item.className} href={item.href}>{item.text}</a></li>
                                        );
                                    })}
                                </ul>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.data.linkText_2}</h3>
                                <ul>
                                    {this.state.iconList2.map((item, idx) => {
                                        return(
                                            <li key={`ci_${idx}`} className="py-2">
                                                <a className="media" href="/#">
                                                    <div className="social-icon mr-3">
                                                        <i className={item.iconClass} />
                                                    </div>
                                                    <span className="media-body align-self-center">{item.text}</span>
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.data.linkText_3}</h3>
                                {/* Store Buttons */}
                                <div className="button-group store-buttons store-black d-flex flex-wrap">
                                    {this.state.footerList_3.map((item, idx) => {
                                        return(
                                            <a key={`flth_${idx}`} href="/#">
                                                <img src={item.image} alt="" />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* Footer Bottom */}
                    <div className="footer-bottom">
                        <div className="container">
                        <div className="row">
                            <div className="col-12">
                            {/* Copyright Area */}
                            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                {/* Copyright Left */}
                                <div className="copyright-left">© Copyrights {new Date().getFullYear()} Sanghi softech All rights reserved.</div>
                                {/* Copyright Right */}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default FooterSection;