import React, { Component, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { GoogleFormProvider, useGoogleForm, useShortAnswerInput } from 'react-google-forms-hooks'
import { googleFormsToJson } from 'react-google-forms-hooks'
const ContactFormLeft = ()=> {
  useEffect(()=>{
    getFormData();
  },[])

  const getFormData = async ()=>{
    // const result = await googleFormsToJson(
    //   'https://docs.google.com/forms/d/e/1FAIpQLSf-5MbhwQCKZR1XLWHz1ZegBdW2T69LWH_8r_uassqNFnF-qA/viewform'
    // )
    
    // console.log(result)
  }

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [address, setAddress] = useState('')

  const onSubmit = (ev)=> {
    ev.preventDefault();
    if(!name){
      toast.error('Name is required',{
        autoClose: 2000
      })
      return;
    } else if(!email){
      toast.error('Email is required',{
        autoClose: 2000
      })
      return;
    } else if(!phoneNumber){
      toast.error('Phone Number is required',{
        autoClose: 2000
      })
      return;
    }
    toast.info('Submitting Form',{
      autoClose: 2000
    })
  }

  return (
    <div className="contact-box text-center">
      <form
        onSubmit={onSubmit}
        className="contact-form"
      >
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Name *"
              required="required"
              onChange={(ev)=>setName(ev.target.value)}
              value={name}
              />
            </div>
            <div className="form-group">
              <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Email *"
              required="required"
              onChange={(ev)=>setEmail(ev.target.value)}
              value={email}
              />
            </div>
            <div className="form-group">
              <input
              type="number"
              className="form-control"
              name="phoneNumber"
              placeholder="Phone Number *"
              required="required"
              onChange={(ev)=>setPhoneNumber(ev.target.value)}
              value={phoneNumber}
              />
            </div>
          </div>
          <div className="col-12">
              <div className="form-group">
                  <textarea
                  className="form-control"
                  name="address"
                  placeholder="Address"
                  onChange={(ev)=>setAddress(ev.target.value)}
                  value={address}
                  />
              </div>
          </div>
          <div className="col-12">
              <button
                  type="submit"
                  className="btn btn-lg btn-block mt-3"><span className="text-white pr-3"><i className="fas fa-paper-plane" /></span>
                  Join
              </button>
          </div>
        </div>
      </form>
      {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf-5MbhwQCKZR1XLWHz1ZegBdW2T69LWH_8r_uassqNFnF-qA/viewform?embedded=true" style={{display: 'none'}}>Loading…</iframe> */}
    </div>
  );
}

export default ContactFormLeft;