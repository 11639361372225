import React, { Component } from 'react';

import Header from '../components/HeaderSection/Header';
import HeroSection from '../components/HeroSection/HeroOne';
import FeatureSection from '../components/Features/FeatureOne';
import Work from '../components/WorkSection/Work';
import Download from '../components/DownloadSection/Download';
import ContactSection from '../components/ContactSection/Contact';
import ContactLeft from '../components/ContactSection/ContactLeft';
import FooterSection from '../components/FooterSection/Footer';

class ThemeOne extends Component {
    componentDidMount(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/621375fca34c24564127568a/1fse09oum';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
    }
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    <HeroSection />
                    <FeatureSection />
                    <Work />
                    <ContactLeft />
                    <Download />
                    <ContactSection />
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default ThemeOne;