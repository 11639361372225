import React, { Component } from 'react';

class FeatureSection extends Component {
    state = {
        data: {},
        featureData: []
    }
    render() {
        return (
            <section id="features" className="section features-area style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>About Us</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 res-margin">
                            <div className="col-12">
                            Sanghi Softech is young, imaginative and eager to bring a change in the enterprise spectrum through deep rooted technological expertise. We build products that fit your business in the contemporary setup and prepare you for the uncalled challenges of tomorrow.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{paddingTop: 50, paddingBottom: 50}}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>Our Web Services</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        
                        <div className="col-6 col-md-6 col-lg-3 res-margin">
                            {/* Image Box */}
                            <div className="image-box text-center icon-1 p-5" style={{height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                                {/* Featured Image */}
                                {/* <div className="featured-img mb-3">
                                    <img className="avatar-sm" src={item.image} alt="" />
                                </div> */}
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h3 className="mb-2">Web Apps</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-3 res-margin">
                            <div className="image-box text-center icon-1 p-5" style={{height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                                {/* Featured Image */}
                                {/* <div className="featured-img mb-3">
                                    <img className="avatar-sm" src={item.image} alt="" />
                                </div> */}
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h3 className="mb-2">Mobile Apps</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-3 res-margin">
                            <div className="image-box text-center icon-1 p-5" style={{height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                                {/* Featured Image */}
                                {/* <div className="featured-img mb-3">
                                    <img className="avatar-sm" src={item.image} alt="" />
                                </div> */}
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h3 className="mb-2">UI/UX</h3>
                                </div>
                            </div>
                        </div>  
                        <div className="col-6 col-md-6 col-lg-3 res-margin">
                            <div className="image-box text-center icon-1 p-5" style={{height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                                {/* Featured Image */}
                                {/* <div className="featured-img mb-3">
                                    <img className="avatar-sm" src={item.image} alt="" />
                                </div> */}
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h3 className="mb-2">Devops</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container ">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>Other Services</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        
                        <div className="col-6 col-md-6 col-lg-3 res-margin">
                            {/* Image Box */}
                            <div className="image-box text-center icon-1 p-5" style={{height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                                {/* Featured Image */}
                                {/* <div className="featured-img mb-3">
                                    <img className="avatar-sm" src={item.image} alt="" />
                                </div> */}
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h3 className="mb-2">Social Media Management</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-3 res-margin">
                            <div className="image-box text-center icon-1 p-5" style={{height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                                {/* Featured Image */}
                                {/* <div className="featured-img mb-3">
                                    <img className="avatar-sm" src={item.image} alt="" />
                                </div> */}
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h3 className="mb-2">Logo/Flyer Design</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-3 res-margin">
                            <div className="image-box text-center icon-1 p-5" style={{height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                                {/* Featured Image */}
                                {/* <div className="featured-img mb-3">
                                    <img className="avatar-sm" src={item.image} alt="" />
                                </div> */}
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h3 className="mb-2">SEO</h3>
                                </div>
                            </div>
                        </div>  
                        <div className="col-6 col-md-6 col-lg-3 res-margin">
                            <div className="image-box text-center icon-1 p-5" style={{height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                                {/* Featured Image */}
                                {/* <div className="featured-img mb-3">
                                    <img className="avatar-sm" src={item.image} alt="" />
                                </div> */}
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h3 className="mb-2">Advertising Solutions</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureSection;