import React, { Component } from 'react';
import ContactFormLeft from './ContactFormLeft';
import Image from './image-contact.png';

class ContactLeft extends Component {
    state = {
        data: {
            "heading": "If you are a developer or designer. Join us.",
            "iconList": [
              {
                "id": 1,
                "iconClass": "fas fa-home",
                "text": "Vestibulum nulla libero, convallis, tincidunt suscipit diam, DC 2002"
              },
              {
                "id": 2,
                "iconClass": "fas fa-phone-alt",
                "text": "+1 230 456 789-012 345 6789"
              },
              {
                "id": 3,
                "iconClass": "fas fa-envelope",
                "text": "exampledomain@domain.com"
              }
            ]
          },
        iconList: [
            {
              "id": 1,
              "iconClass": "fas fa-home",
              "text": "Vestibulum nulla libero, convallis, tincidunt suscipit diam, DC 2002"
            },
            {
              "id": 2,
              "iconClass": "fas fa-phone-alt",
              "text": "+1 230 456 789-012 345 6789"
            },
            {
              "id": 3,
              "iconClass": "fas fa-envelope",
              "text": "exampledomain@domain.com"
            }
          ]
    }
    
    render() {
        return (
            <section id="joinus" className="contact-area bg-gray ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2 className="text-capitalize">{this.state.data.heading}</h2>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-between">
                    <div className="col-12 col-md-6 pt-4 pt-md-0">
                        <ContactFormLeft />
                    </div>
                    <div className="col-12 col-md-5 p-2">
                        <img src={Image} style={{width: '100%'}}/>
                    </div>
                    
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactLeft;