import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeOne from "../themes/theme-one";
import PrivacyPolicy from "../themes/privacy-policy";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeOne} />
          </Switch>
          <Switch>
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;