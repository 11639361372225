import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as optimizelyReactSDK from '@optimizely/react-sdk';

// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes'

// Instantiate an Optimizely client
const optimizely = optimizelyReactSDK.createInstance({
  sdkKey: 'SXQJYFnLgLcJmdDDYSanY',
});

console.log(optimizely);

function App() {
  return (
    <div>
      <MyRouts />
      <ToastContainer />
    </div>
  );
}

export default App;
