import React, { Component } from "react";
import { toast } from "react-toastify";
import Axios from "axios";


class ContactForm extends Component {

  constructor(props) {
    super(props)
    this.myForm = React.createRef()
  }

  state = {
    name: '',
    email: '',
    subject: '',
    message: ''
  }

  changHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  submitHangler = async (event) => {
    event.preventDefault();
    if(!this.state.name){
      toast.error('Name is required',{
        autoClose: 2000
      })
      return;
    } else if(!this.state.email){
      toast.error('Email is required',{
        autoClose: 2000
      })
      return;
    } else if(!this.state.message){
      toast.error('Message is required',{
        autoClose: 2000
      })
      return;
    }
    toast.info('Submitting Form',{
      autoClose: 2000
    })
    try{
      // let res = await Axios.post('https://us-central1-bidlocal-web.cloudfunctions.net/api/send-contact-mail',this.state);
      // if(res.status !== 200){
      //   throw new Error('Internal Server Error')
      // }
      toast.success('Form Submitted Successfully',{
        autoClose: 2000
      })
    } catch(err){
      toast.error('Error while submitting form. Please drop a mail on our support.',{
        autoClose: 2000
      })
      return
    } finally {
      this.myForm.current.reset()
      this.setState({
        name: '',
        email: '',
        subject: '',
        message: ''
      })
    }
  }

  render() {
    return (
      <div className="contact-box text-center">
        <form
          ref={this.myForm}
          onSubmit={this.submitHangler}
          className="contact-form"
          noValidate="novalidate"
        >
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Name *"
                required="required"
                onChange={this.changHandler}
                value={this.state.name}
                />
              </div>
              <div className="form-group">
                <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email *"
                required="required"
                onChange={this.changHandler}
                value={this.state.email}
                />
              </div>
              <div className="form-group">
                <input
                type="text"
                className="form-control"
                name="subject"
                placeholder="Subject"
                required="required"
                onChange={this.changHandler}
                value={this.state.subject}
                />
              </div>
            </div>
            <div className="col-12">
                <div className="form-group">
                    <textarea
                    className="form-control"
                    name="message"
                    placeholder="Message *"
                    required="required"
                    onChange={this.changHandler}
                    value={this.state.message}
                    />
                </div>
            </div>
            <div className="col-12">
                <button
                    type="submit"
                    className="btn btn-lg btn-block mt-3"><span className="text-white pr-3"><i className="fas fa-paper-plane" /></span>
                    Send Message
                </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;