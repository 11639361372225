import React, { Component } from 'react';

class Work extends Component {
    state = {
        data: {},
        workData: [
            {
              "id": 1,
              "image": "/img/work_thumb_1.png",
              "title": "Reach us using Mail or Chatbot",
            },
            {
              "id": 2,
              "image": "/img/work_thumb_2.png",
              "title": "Discuss your requirement",
            },
            {
              "id": 3,
              "image": "/img/work_thumb_3.png",
              "title": "Get your product!",
            }
          ]
    }
    render() {
        return (
            <section className="section work-area bg-overlay overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-6">
                            {/* Work Content */}
                            <div className="work-content text-center">
                                <h2 className="text-white mb-4 ">How to initiate?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.workData.map((item, idx) => {
                            return(
                                <div key={`w_${idx}`} className="col-12 col-md-4">
                                {/* Single Work */}
                                <div className="single-work text-center p-3">
                                    {/* Work Icon */}
                                    <div className="work-icon">
                                        <img className="avatar-md" src={item.image} alt="" />
                                    </div>
                                    <h3 className="text-white py-3">{item.title}</h3>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default Work;